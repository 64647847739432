
import { Component, Vue } from "vue-property-decorator";

@Component
export default class OsmConsent extends Vue {
  public accept() {
    localStorage.setItem("osmConsent", "true");
    this.$root.$emit("update_osm_consent");
  }
  public decline() {
    localStorage.setItem("osmConsent", "false");
    this.$root.$emit("update_osm_consent");
  }
}
