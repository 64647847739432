
import { Component, Vue } from "vue-property-decorator";

@Component
export default class OsmConsentNotice extends Vue {
  public showAgain() {
    localStorage.removeItem("osmConsent");
    this.$root.$emit("update_osm_consent");
  }
}
